import React, { useEffect, useRef, useState } from 'react';
import ReactFlow, { Handle, NodeProps, NodeTypes, PanOnScrollMode, Position } from 'reactflow';
import 'reactflow/dist/style.css';

import { ArrowBackIosNew } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import BookSvg from './components/BookSvg';
import CrownSvg from './components/CrownSvg';
import PlayCircleSvg from './components/PlayCircleSvg';
import StarSvg from './components/StarSvg';
import SvgWrapper from './components/SvgWrapper';

interface PDPBlockData {
    id: string;
    color: string;
    icon?: React.ReactElement;
    name: string;
    description: string;
    endDesc?: string;
    onClick: () => void;
}


const PDPBlock: React.FC<NodeProps<PDPBlockData>> = ({ data }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                    transform: isHovered ? 'scale(0.9)' : 'scale(1)',
                    transition: 'transform 0.3s ease',
                }}
                onClick={data.onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div>
                    <SvgWrapper color={data.color} icon={data.icon} />
                    <Handle type="source" position={Position.Left} id={`${data.id}`} style={{ marginLeft: 50, zIndex: -5 }} />
                    <Handle type="target" position={Position.Right} id={`${data.id}`} style={{ marginRight: 50, zIndex: -5 }} />
                </div>


            </div>
            <span
                style={{
                    fontSize: '20px',
                    fontWeight: '700',
                    textAlign: 'center',
                    color: data.color,
                    position: 'absolute',
                    width: '180px',
                    marginLeft: '-20px',
                }}
            >

                {data.name}
                <br />
                <div style={{ fontSize: '14px' }}>{data.description}</div>
            </span>
            {data.endDesc&&  <TopicSeparator name={data.endDesc}/>}
           
        </>
    );
}

const EnrollButton = () => {

return <div
style={{
    position: 'absolute', // Absolutely position the div
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 10, // Higher z-index to place it above ReactFlow
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}}
>
<div
    style={{
        paddingLeft: 28,
        paddingRight: 28,
        paddingTop: 13,
        paddingBottom: 13,
        background: '#7749F8',
        boxShadow: '0px 8px 0px #C2ACFF',
        borderRadius: 28,
        display: 'inline-flex',
    }}
>
    <div
        style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 125,
            display: 'inline-flex',
        }}
    >
        <div
            style={{
                width: 215,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 16,
                display: 'inline-flex',
            }}
        >
            <div
                style={{
                    alignSelf: 'stretch',
                    opacity: 0.58,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 13,
                    display: 'inline-flex',
                }}
            >
                <ArrowBackIosNew fontSize="small" style={{ color: '#fff' }} />
                <div
                    style={{
                        color: 'white',
                        fontSize: 14,
                        fontFamily: 'Inter',
                        fontWeight: '600',
                        textTransform: 'uppercase',
                        wordWrap: 'break-word',
                    }}
                >
                    CHAPTER 1, TOPIC 1
                </div>
            </div>
            <div
                style={{
                    width: 372,
                    color: 'white',
                    fontSize: 15,
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    textTransform: 'uppercase',
                    wordWrap: 'break-word',
                }}
            >
               Characteristics of Contemporary Processors and Devices
            </div>
        </div>
        <div
            style={{
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 16,
                paddingBottom: 16,
                background: '#8F00E1',
                boxShadow: '0px 5px 0px rgba(0, 0, 0, 0.25)',
                borderRadius: 47,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 8,
                display: 'flex',
            }}
        >
            <div
                style={{
                    color: 'white',
                    fontSize: 16,
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    wordWrap: 'break-word',
                }}
            >
                bookmark
            </div>
        </div>
    </div>
</div>
</div>
}
const TopicSeparator = ({ name }: { name: string }) => {
    return (
        <div style={{ width: '700px', height: '100%', justifyContent: 'center', alignItems: 'center', gap: 37, display: 'inline-flex', position: "absolute", left: "50%", transform: "translateX(-50%)", top: 200 }}>
            <div style={{ width: 368, height: 0, border: '4px rgba(0, 0, 0, 0.30) solid' }}></div>
            <div style={{ color: 'rgba(0, 0, 0, 0.30)', fontSize: 16, fontFamily: 'Inter', fontWeight: '700', textTransform: 'uppercase', width: "auto" }}>{name}</div>
            <div style={{ width: 368, height: 0, border: '4px rgba(0, 0, 0, 0.30) solid' }}></div>
        </div>
    )
}

const nodeTypes: NodeTypes = { pdpBlock: PDPBlock };

const PDP: React.FC = () => {
    const navigate = useNavigate();
    const { topic, origClassId } = useParams()
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [edges] = useState([
        { id: 'e1-2', source: '1', target: '2', sourceHandle: '1', animated: true },
        { id: 'e2-3', source: '2', target: '3', sourceHandle: '2', animated: true },
        { id: 'e3-4', source: '3', target: '4', sourceHandle: '3', animated: true },
    ]);
    const [nodes] = useState([
        {
            id: '1',
            type: 'pdpBlock',
            position: { x: 100, y: 150 },
            data: {
                color: '#7749F8', name: 'Introduction video', description: "Quick describary video", icon: <PlayCircleSvg />, onClick: () => {
                    window.open("https://www.youtube.com/watch?v=OIQlh4EXCCI&t=")
                }
            },
        },
        {
            id: '2',
            type: 'pdpBlock',
            position: { x: 100, y: 450 },
            data: { color: '#8F00E1', name: 'Read content', description: "All content related to this subtopic", icon: <BookSvg />, onClick: () => { navigate("/myclasses/learn/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48") } },
        },
        {
            id: '3',
            type: 'pdpBlock',
            position: { x: 100, y: 750 },
            data: { color: '#7749F8', name: 'Test yourself', description: "Questions needed to test your knowledge.\nProgress 0/5", icon: <StarSvg />, onClick: () => { navigate("/Class/Questions/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48") } },
        },
        {
            id: '4',
            type: 'pdpBlock',
            position: { x: 100, y: 1050 },
            data: { color: '#FF8A00', name: 'EXAM', icon: <CrownSvg />, description: "Final exam to test your knowledge\n You need to get at least 75% to pass this content!",endDesc:"Deep dive" },
        },
        {
            id: '5',
            type: 'pdpBlock',
            position: { x: 100, y: 150 },
            data: {
                color: '#7749F8', name: 'Introduction video', description: "Quick describary video", icon: <PlayCircleSvg />, onClick: () => {
                    window.open("https://www.youtube.com/watch?v=OIQlh4EXCCI&t=")
                }
            },
        },
        {
            id: '6',
            type: 'pdpBlock',
            position: { x: 100, y: 450 },
            data: { color: '#8F00E1', name: 'Read content', description: "All content related to this subtopic", icon: <BookSvg />, onClick: () => { navigate("/myclasses/learn/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48") } },
        },
        {
            id: '7',
            type: 'pdpBlock',
            position: { x: 100, y: 750 },
            data: { color: '#7749F8', name: 'Test yourself', description: "Questions needed to test your knowledge.\nProgress 0/5", icon: <StarSvg />, onClick: () => { navigate("/Class/Questions/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48") } },
        },
        {
            id: '8',
            type: 'pdpBlock',
            position: { x: 100, y: 1050 },
            data: { color: '#FF8A00', name: 'EXAM', icon: <CrownSvg />, description: "Final exam to test your knowledge\n You need to get at least 75% to pass this content!",},
        },
      {
            id: '9',
            type: 'pdpBlock',
            position: { x: 100, y: 150 },
            data: {
                color: '#7749F8', name: 'Introduction video', description: "Quick describary video", icon: <PlayCircleSvg />, onClick: () => {
                    window.open("https://www.youtube.com/watch?v=OIQlh4EXCCI&t=")
                }
            },
        },
        {
            id: '10',
            type: 'pdpBlock',
            position: { x: 100, y: 1350 },
            data: { color: '#8F00E1', name: 'Read content', description: "All content related to this subtopic", icon: <BookSvg />, onClick: () => { navigate("/myclasses/learn/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48") } },
        },
        {
            id: '11',
            type: 'pdpBlock',
            position: { x: 100, y: 1650 },
            data: { color: '#7749F8', name: 'Test yourself', description: "Questions needed to test your knowledge.\nProgress 0/5", icon: <StarSvg />, onClick: () => { navigate("/Class/Questions/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48") } },
        },
        {
            id: '12',
            type: 'pdpBlock',
            position: { x: 100, y: 1950 },
            data: { color: '#FF8A00', name: 'EXAM', icon: <CrownSvg />, description: "Final exam to test your knowledge\n You need to get at least 75% to pass this content!" },
        },
           {
            id: '13',
            type: 'pdpBlock',
            position: { x: 100, y: 2250 },
            data: {
                color: '#7749F8', name: 'Introduction video', description: "Quick describary video", icon: <PlayCircleSvg />, onClick: () => {
                    window.open("https://www.youtube.com/watch?v=OIQlh4EXCCI&t=")
                }
            },
        },
        {
            id: '14',
            type: 'pdpBlock',
            position: { x: 100, y: 2550 },
            data: { color: '#8F00E1', name: 'Read content', description: "All content related to this subtopic", icon: <BookSvg />, onClick: () => { navigate("/myclasses/learn/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48") } },
        },
        {
            id: '15',
            type: 'pdpBlock',
            position: { x: 100, y: 2850 },
            data: { color: '#7749F8', name: 'Test yourself', description: "Questions needed to test your knowledge.\nProgress 0/5", icon: <StarSvg />, onClick: () => { navigate("/Class/Questions/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48") } },
        },

    ]);

    useEffect(() => {
        const paneElement = document.querySelector('.react-flow__pane') as HTMLElement;
        if (paneElement) {
            paneElement.style.cursor = 'default';
        }
        const viewportElement = document.querySelector('.react-flow__viewport');
        const containerElement = document.querySelector('.react-flow__container') as HTMLElement;

        if (viewportElement && containerElement) {
            const change =  0;
            console.log(-(nodes[nodes.length - 1].position.y));
            setReset((prev) => {
                const newValue = (prev - change);
                if (newValue <= 0 && newValue > -(nodes[nodes.length - 1].position.y - 450)) {
                    return newValue;
                }
                return prev;
            });

            containerElement.style.transform = `translate(40%, ${reset}px) scale(1)`;
        }
    }, []);
    const [reset, setReset] = useState(0);
    return (

        <div
            ref={reactFlowWrapper}
            style={{
                position: 'relative', // Make the parent container relative
                height: '100%',
                width: '100%',
                cursor: 'default',
            }}
        >
            <EnrollButton />

            <ReactFlow
                onPaneClick={() => { }}
                ref={reactFlowWrapper}
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                minZoom={1}
                fitView={true}

                maxZoom={1}
                draggable={false}
                panOnDrag={false}
                panOnScrollMode={PanOnScrollMode.Vertical} // Change this to Horizontal if needed
                panOnScroll={false} // Enable panning on scroll
                
                style={{ scrollBehavior: 'smooth', cursor: 'default' }}
                onPaneScroll={(e) => {
                    const viewportElement = document.querySelector('.react-flow__viewport');
                    const containerElement = document.querySelector('.react-flow__container') as HTMLElement;

                    if (viewportElement && containerElement) {
                        const change = e?.deltaY || 0;
                        console.log(-(nodes[nodes.length - 1].position.y));
                        setReset((prev) => {
                            const newValue = (prev - change);
                            if (newValue <= 0 && newValue > -(nodes[nodes.length - 1].position.y - 450)) {
                                return newValue;
                            }
                            return prev;
                        });

                        containerElement.style.transform = `translate(40%, ${reset}px) scale(1)`;
                    }
                }}
            >
            </ReactFlow>
            
        </div>
    );
};

export default PDP;
