import { Lock } from "@mui/icons-material";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";

const ChangePassword = () => {
  return (
    <Box bgcolor={'white'} borderRadius={'.75rem'} p={'1.5rem'}>
      <Typography fontWeight={600} fontSize={'1.5rem'} lineHeight={'1.8156rem'}>Change Password</Typography>
      <TextField
        placeholder="Current Password"
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder="Confirm New Password"
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
        }}
      />
      <Button sx={{ bgcolor: '#5227CC', color: 'white', py: '.5rem', px: '1rem' }}>
        Confirm
      </Button>
    </Box>
  );
};

export default ChangePassword;
