import BotAvatar from '@mui/icons-material/Android';
import CloseIcon from '@mui/icons-material/Close';
import UserAvatar from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import { Avatar, Box, IconButton, Modal, TextField } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import assets from '../../assets';
import urlConfig from '../../configs/urlConfig';
import { Question } from '../../interfaces/Question';
import useGenericMutation from '../shared/useGenericMutation';

interface ChatBotProps {
  isOpen: boolean;
  question?: Question;
  handleClose: () => void;
  answer?: string;
  scoredMarks?: number;
  feedback?: string;
}

const BotLoadingImage = styled.img`
    width: 60px; 
    height: 30px;
    display: block;
    margin: 0 auto 10px;
`;

const LOADING = 'Loading'

const chatHelp = async ({ questionData, messages, answer, scoredMarks, feedback }: { questionData: Question, messages: string[], answer: string, scoredMarks: number, feedback: string }) => {
  try {
    const identifier = Cookies.get('access_token')
    const response = await fetch(`${urlConfig.apiUrl}/chatHelp`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        identifier: identifier,
        questionData: { ...questionData, answer: answer, feedback: feedback, marks: scoredMarks, maxMarks: questionData.maxMarks },
        message: messages,

      }),
    });

    /**
     * Let the chatbot return both the success and 429 messages to user
     */
    // if (response.ok) {
    const textResponse = await response.text();
    const newBotMessage = { sender: 'bot', content: textResponse };
    // setMessages(prevMessages => [...prevMessages, newBotMessage]);

    return newBotMessage;
    // } else {
    //   console.error('Error with status:', response.status);
    // }
  } catch (error) {
    console.error('Error:', error);
  }
}

const useChatbotSend = () => {
  return useGenericMutation({
    mutationKey: ['revisionContent'],
    mutationFn: chatHelp,
    onSuccess: (data) => {
      return data;
    },
    // onError: (error) => {
    //   console.log('Error enrolling:', error);
    // }
  });
}
const ChatBot: React.FC<ChatBotProps> = ({ isOpen, question, handleClose, scoredMarks, answer, feedback }) => {

  const [messages, setMessages] = useState([
    { sender: 'bot', content: 'How can I help you today?' },
  ]);
  const [message, setMessage] = useState('');

  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { mutate: sendChatMessage, data: chatMessage, isPending } = useChatbotSend();

  useEffect(() => {
    chatMessage && setMessages(prevMessages => [...prevMessages, chatMessage]);
  }, [chatMessage])


  useEffect(() => {
    if (isPending) {
      setMessages(prevMessages => [...prevMessages, { sender: 'bot', content: LOADING },]);
    } else {
      setMessages(prevMessages => prevMessages.filter(message => message.content !== LOADING));
    }

  }, [isPending])

  useEffect(scrollToBottom, [messages]);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };


  const handleSendMessage = async () => {
    if (message.trim()) {
      const newUserMessage = { sender: 'user', content: message };
      setMessages(prevMessages => [...prevMessages, newUserMessage]);

      sendChatMessage({
        questionData: question!,
        messages: [...messages.map(msg => msg.content), message],
        answer: answer!,
        scoredMarks: scoredMarks!,
        feedback: feedback!,
      })
      setMessage('');
    }
  };


  const renderMessages = () => {
    return messages.map((msg, index) => (
      <Box key={index} sx={{
        display: 'flex',
        justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
        alignItems: 'flex-end',
        mb: 1,
      }}>
        {msg.sender === 'bot' && (
          <Avatar sx={{ mr: 1, bgcolor: 'lightgray' }}>
            <BotAvatar />
          </Avatar>
        )}
        <Box sx={{
          maxWidth: '60%',
          padding: '8px 16px',
          borderRadius: '20px',
          bgcolor: msg.sender === 'bot' ? 'white' : 'lightgreen',
          textAlign: 'left',
          display: 'inline-block',
          wordBreak: 'break-word',
        }}>
          {msg.content === LOADING ? <BotLoadingImage src={assets.gifs.botLoading} alt="Waving bot" /> : msg.content}
        </Box>
        {msg.sender === 'user' && (
          <Avatar sx={{ ml: 1, bgcolor: 'lightblue' }}>
            <UserAvatar />
          </Avatar>
        )}
      </Box>
    ));
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        p: 1,
      }}
    >
      <Box
        sx={{
          width: '35vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
        }}
      >
        <Box sx={{
          bgcolor: '#f5f5f5',
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}>
          <Box sx={{ ml: 2, fontWeight: 'bold', fontSize: '1.2rem' }}>
            ChatBot
          </Box>
          <IconButton onClick={handleClose} size="small" sx={{ mr: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{
          flexGrow: 1,
          overflowY: 'auto',
          p: 2,
          bgcolor: '#f0f0f0',
        }}>
          {renderMessages()}
          <div ref={endOfMessagesRef} />
        </Box>

        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          borderTop: '1px solid #e0e0e0',
        }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Type a message..."
            value={message}
            onChange={handleMessageChange}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            sx={{
              borderRadius: 2,
              mr: 1,
            }}
          />
          <IconButton color="primary" onClick={handleSendMessage}>
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChatBot;
