import React from 'react';

type Props = {};

const ClassTwo = (props: Props) => {
  return (
    <div>History class</div>
  );
};

export default ClassTwo;