import React, { ReactElement } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ChatBot from '../../components/common/Chatbot';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useMutation } from '@tanstack/react-query';
import assets from '../../assets';
import { Question } from '../../interfaces/Question';
import { markAnswer, useVideoLinkQA } from '../../services/questions';

import { CircularProgress } from '@mui/material';
import Feedback, { EndQFeedback, SlideDataFeedback, VideoDataFeedback } from '../../interfaces/Feedback';
import {
  AnsweredModal, AnsweredModalBody, AnsweredModalContent,
  AnsweredModalFooter,
  AnsweredModalHeader,
  AnsweredModalTitle, ButtonSection, Buttons, ClassDeck,
  CloseButton,
  FullFeedbackButton,
  QuestionDeckChatBot, QuestionDeckDifficulty, QuestionDeckFavAndButton, QuestionDeckHeader,
  QuestionDeckMarks, QuestionDeckTopRightItems, QuestionInput,
  QuestionSection,
  QuestionTop,
  // Subheading,
  SubmitButton,
  TryAgainButton,
  WavingImage,
  WavingImageContainer
} from './QuestionCard.styles';

import Box from '@mui/joy/Box';
import Checkbox from '@mui/joy/Checkbox';

import FeedbackOverlay from './components/FeedbackOverlay';
import HelpOverlay from './components/HelpOverlay';
import PictureAnswer from './components/PictureAnswer';
interface ElementC {
  // questionData: Question;
  questionData: Question;
  originalClassId: string;
}

export interface PictureData {
  id: string;
  pictureDataUrl: string;
  readOut: string;
  title: string;
}


const dificultyColors: { [key: string]: string } = {
  "Easy": "#34d399",
  "Medium": "#facc15",
  "Hard": "#ef4444",
  "Insane": "#a855f7",
}



const getDificultyColor = (questionDifficulty: string): string => {
  return dificultyColors[questionDifficulty]
}



const QuestionCard: React.FC<ElementC> = ({ questionData, originalClassId }): ReactElement | null => {
  const [pictureData, setPictureData] = useState<PictureData[]>([]);

  const { mutate: helpVideo, data: helpVideoUrl, isSuccess: isHelpVideoSuccess, isError: isHelpVideoError } = useVideoLinkQA();

  //ignore this below pls i added this here because i was having issues when disabling the scrolling and then going to a different page before closing modal
  const location = useLocation();


  const [feedback, setFeedback] = useState<Feedback>();
  const [endQFeedback, setEndQFeedback] = useState<EndQFeedback>()
  const [, setSlideData] = useState<SlideDataFeedback>()
  const [videoData, setVideoData] = useState<VideoDataFeedback>()
  const [reset, setReset] = useState(0)
  const [isChatBotOpen, setChatBotOpen] = useState<boolean>(false);
  const [focusedQuestionId, setFocusedQuestionId] = useState<string>('');
  const [focusedQuestionAnswer, setFocusedQuestionAnswer] = useState<string>('')
  const [activeSubmitModals, setActiveSubmitModals] = useState<{ [key: string]: boolean }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNeedHelpModal, setShowNeedHelpModal] = useState(false);
  const [isNeedHelpProcessing, setIsNeedHelpProcessing] = useState(false);
  const [addPicturesModal, setAddPicturesModal] = useState(false);
  const [isFeedbackProcessing, setIsFeedbackProcessing] = useState(false);

  const [question, setQuestion] = useState<Question>(questionData);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const [favorites, setFavorites] = useState<{ [key: string]: boolean }>({});

  // const [focusedRevisionContent, setFocusedRevisionContent] = useState('')

  const parseResponse = async (response: any) => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf8');
    let result = '';
    let done = false;

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      if (value) {
        // Decode the received chunk
        result += decoder.decode(value, { stream: !done });

        // Check for closing brace '}' which indicates the end of a JSON object
        let splitIndex;
        try {

          while ((splitIndex = result.indexOf('}{')) !== -1) {
            // Split and parse each JSON object
            const jsonString = result.slice(0, splitIndex + 1);


            handleData(JSON.parse(jsonString));
            // parsedData.push(JSON.parse(jsonString));

            result = result.slice(splitIndex + 1);
          }
          //Incase the last result or original result doesnt contain any delimiters
          if ((splitIndex = result.indexOf('}{')) === -1) {
            //The resopnse contains only one json string
            handleData(JSON.parse(result))
          }
        } catch (e) {
          console.error('Error parsing JSON:', e);

        }
      }
    }

  };

  const mutation = useMutation(
    {
      mutationFn: markAnswer,
      onSuccess: (response) => {
        parseResponse(response)
        setIsSubmitting(false)
      },
      onError(error, variables, context) {
        setIsSubmitting(false);
      },

    })

  useEffect(() => {
    setIsNeedHelpProcessing(false);
  }, [isHelpVideoError])


  useEffect(() => {
    const enableBodyScroll = () => {
      document.body.style.overflow = 'auto';
    };
    enableBodyScroll();
    return enableBodyScroll;
  }, [location]);

  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = 'auto';
  };



  const handleOpenChatBot = () => {
    setChatBotOpen(true);
  };

  const handleCloseChatBot = () => {
    setChatBotOpen(false);
  };

  const adjustHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    setFocusedQuestionAnswer(e.target.value)
  };

  const handleFocus = (id: string): void => {
    setFocusedQuestionId(id);
  };


  const handleSubmit = (id: string): void => {
    if (questionData.type == "text-question") {
      setFocusedQuestionId(id)
      setIsSubmitting(true)
      let imageAnswers = ""
      pictureData.map((data, index) => {
        imageAnswers += data.title + ": " + data.readOut + " "
      })
      mutation.mutateAsync({ _id: id, answer: focusedQuestionAnswer + "\n" + imageAnswers,  originalClassId:  originalClassId })
      setActiveSubmitModals((prevModals) => ({ ...prevModals, [id]: true }));
    } else {
      setFocusedQuestionId(id)
      setActiveSubmitModals((prevModals) => ({ ...prevModals, [id]: true }));
    }
  };

  const closeSubmitModal = (id: string): void => {
    setActiveSubmitModals((prevModals) => ({ ...prevModals, [id]: false }));
    setFocusedQuestionId('')
  };


  const handleData = (data: any) => {
    console.log(data);
    switch (data.type) {
      case 'ENDQ':
        setEndQFeedback(data);
        break;
      case 'GOTWRONG':
        setFeedback(data);
        break;
      case 'SLIDEDATA':
        setSlideData(data);
        break;
      case 'VIDEO':
        setVideoData(data);
        break;
      default:
        console.warn('Unknown data type:', data.type);
    }
  };

  useEffect(() => {

    if (isHelpVideoSuccess) {
      setShowNeedHelpModal(true);
      setIsNeedHelpProcessing(false);
      disableBodyScroll();
    }
  }, [isHelpVideoSuccess])


  const handleNeedHelp = (): void => {
    setIsNeedHelpProcessing(true);
    helpVideo(question._id)
  };

  const handleCloseNeedHelpModal = (): void => {
    setShowNeedHelpModal(false);
    setAddPicturesModal(false);
    enableBodyScroll();
  };

  const handleViewFullFeedback = (): void => {
    setShowFeedbackModal(true);
    disableBodyScroll();

  };

  const handleCloseFeedbackModal = (): void => {
    setShowFeedbackModal(false);
    enableBodyScroll();
  };


  const handleFav = (id: string): void => {
    setFavorites(prevFavorites => ({
      ...prevFavorites,
      [id]: !prevFavorites[id]
    }));
  };



  if (question === undefined) {
    return (<> </>)
  }
  // Handle input change
  const handleInputChange = (id: string, e: React.ChangeEvent<HTMLTextAreaElement>) => {
    adjustHeight(e)
    setFocusedQuestionAnswer(e.target.value)
  };



  const getMarksPercentage = (scoredMarks: number, maxMarks: number) => (scoredMarks / maxMarks) * 100;

  const getScoreTitle = (score: number) => {
    if (score < 0 || score > 100) {
      return 'Invalid Score!';
    } else if (score < 25) {
      return 'Keep Going!';
    } else if (score >= 25 && score < 50) {
      return 'Nice work!';

    } else if (score >= 50 && score < 75) {
      return 'Great Job!';

    } else if (score >= 75 && score < 95) {
      return 'Almost Perfect!';
    } else if (score >= 95) {
      return 'Congratulations';
    }

  }

  return (

    <QuestionSection key={question._id}>
      {focusedQuestionId === question._id && (
        <WavingImageContainer onClick={handleOpenChatBot}>
          <WavingImage src={assets.gifs.wavingBot} alt="Waving bot" />
        </WavingImageContainer>
      )}

      <ClassDeck>

        {(activeSubmitModals[question._id]) && (<>

          <AnsweredModal show={activeSubmitModals[question._id]} >
            {question.type == "text-question" ? (<>
              {mutation.isPending && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                <CircularProgress />
              </Box>}
              {mutation.isSuccess && <AnsweredModalContent>
                <AnsweredModalTitle>{getScoreTitle(getMarksPercentage(feedback?.data2?.marks!, Number(question.maxMarks)))}</AnsweredModalTitle>
                <AnsweredModalHeader>You got {endQFeedback?.type ? endQFeedback?.marks : feedback?.data2?.marks || 0}/{question.maxMarks} Marks</AnsweredModalHeader>
                <AnsweredModalBody>{endQFeedback?.feedback || feedback?.data2?.feedback}</AnsweredModalBody>
                <AnsweredModalFooter>
                  <TryAgainButton onClick={() => closeSubmitModal(question._id)}>Try Again</TryAgainButton>
                  <FullFeedbackButton color="#006ecc" isFeedbackProcessing={isFeedbackProcessing} onClick={handleViewFullFeedback}>
                  {isFeedbackProcessing ? 'Processing Full Feedback' : 'View Full Feedback'}
                </FullFeedbackButton>
                </AnsweredModalFooter>
              </AnsweredModalContent>}
              {mutation.isError && <AnsweredModalContent>
                <AnsweredModalTitle>Error</AnsweredModalTitle>
                <AnsweredModalHeader>Oops. Something went wrong.</AnsweredModalHeader>
                <AnsweredModalBody>Error while evaluating your answer -- {mutation.error.message}</AnsweredModalBody>
                <CloseButton onClick={() => closeSubmitModal(question._id)}>Close</CloseButton>
              </AnsweredModalContent>}
            </>)
              :
              <AnsweredModalContent>
                <AnsweredModalTitle>{question.options?.filter((opt) => {
                  return opt.correct!=opt.selected
                }).length==0?"Perfect!":"Please try again!"}</AnsweredModalTitle>
                <AnsweredModalHeader>{question.options?.filter((opt) => {
                  return opt.correct!=opt.selected
                }).length==0?"That's right!":"Your answer was wrong thins time, please try again!"}</AnsweredModalHeader>
                {question.options?.filter((opt) => {
                  return opt.correct!=opt.selected
                }).length==0&&
                <AnsweredModalBody>Good job, the right answer was: {question.options?.map((opt) => {if (opt.correct==true){
                  return <div>{opt.text}</div>
                }})}</AnsweredModalBody>
              }
                
                <CloseButton onClick={() => closeSubmitModal(question._id)} style={{ marginTop: '24px' }}>Try again</CloseButton>
              </AnsweredModalContent>}
          </AnsweredModal></>)}
        <QuestionTop >
          <QuestionDeckHeader>{question.question}</QuestionDeckHeader>
          <QuestionDeckTopRightItems >
            <QuestionDeckDifficulty style={{ backgroundColor: getDificultyColor(question.difficulty) }}>
              <StarBorderIcon style={{ paddingRight: 2, paddingLeft: 2, color: 'white' }} />
              {question.difficulty}
            </QuestionDeckDifficulty>
            <QuestionDeckMarks>{question.maxMarks}<CheckCircleOutlineIcon style={{ color: 'black' }} /></QuestionDeckMarks>
            <QuestionDeckFavAndButton>
              {favorites[question._id] ?
                <StarIcon onClick={() => handleFav(question._id)} style={{ color: 'gold' }} /> :
                <StarBorderIcon onClick={() => handleFav(question._id)} style={{ color: 'black' }} />
              }
              <QuestionDeckChatBot color="#FFF" TextColor="#006ecc" onClick={handleOpenChatBot}>ChatBot</QuestionDeckChatBot>
            </QuestionDeckFavAndButton>
          </QuestionDeckTopRightItems>
        </QuestionTop>

        {questionData.type == "text-question" ?
          <QuestionInput
            placeholder='Write your answer'
            // onChange={adjustHeight}
            onChange={e => handleInputChange(question._id, e)}
            onFocus={() => handleFocus(question._id)}
            onBlur={() => setFocusedQuestionId('')}
            defaultValue=""
            value={focusedQuestionAnswer}
          /> :
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {question.options?.map((option, index) => (
                <Checkbox
                  key={index}
                  label={option.text}
                  defaultChecked={option.selected}
                  checked={question.options?.find((o) => o.text === option.text)?.selected}
                  onChange={() => {
                    setQuestion(prev => {
                      const newOptions = prev.options?.map((optionNew, idx) => {
                        if (optionNew.text === option.text) {
                          if (optionNew.selected == undefined) {
                            optionNew.selected = false
                          }
                          return { ...optionNew, selected: !optionNew.selected }
                        }
                        return optionNew
                      })

                      return { ...prev, options: newOptions }
                    })
                    setReset(prev => prev + 1)
                    console.log("this changed")
                  }}
                />
              ))}
            </Box>
            <div style={{ fontSize: "14px" }}>This question has {question.options?.filter((option) => option.correct).length} correct answer</div>
          </>
        }

        <ButtonSection>
          <SubmitButton disabled={isSubmitting} color="#006ecc" isSubmitting={isSubmitting} TextColor="#FFF" onClick={() => handleSubmit(question._id)}>
            {isSubmitting ? 'Submitting' : 'Submit'}
          </SubmitButton>


          <Buttons color="#F8F9FA" disabled={isNeedHelpProcessing} TextColor="#006ecc" onClick={handleNeedHelp}>{!isNeedHelpProcessing ? 'Need help?' : 'Processing Help'}</Buttons>
          <Buttons color="#F8F9FA" TextColor="#006ecc" onClick={() => { setAddPicturesModal(true) }}>Add picture</Buttons>

          {isHelpVideoError && <div>Error while generating the help. Please try again later!</div>}
        </ButtonSection>
      </ClassDeck>
      <ChatBot isOpen={isChatBotOpen}
        question={question}
        answer={focusedQuestionAnswer}
        feedback={focusedQuestionId ? feedback?.data2?.feedback : ''}
        scoredMarks={feedback?.data2?.marks || 0}
        handleClose={handleCloseChatBot} />

      {
        showFeedbackModal && <FeedbackOverlay
          feedback={feedback!} questionData={questionData} videoData={videoData!}
          handleCloseFeedbackModal={handleCloseFeedbackModal} handleOpenChatBot={handleOpenChatBot} />
      }

      {
        showNeedHelpModal && <HelpOverlay handleCloseNeedHelpModal={handleCloseNeedHelpModal}
          helpVideoUrl={helpVideoUrl} question={question} />
      }

      {
        addPicturesModal && <PictureAnswer handleCloseNeedHelpModal={handleCloseNeedHelpModal} qid={question._id} pictureData={pictureData} setPictureData={setPictureData} />
      }

    </QuestionSection >
  );
};

export default QuestionCard;