
import { Question } from '../../../interfaces/Question';
import {
    CloseButton,
    ContentList,
    ContentSubTitle,
    ContentTile,
    FeedbackModalOverlay,
    FeedbackModalTopBar,
    FeedbackModalWrapper,
    ModalTitle,
    VideoClipPlaceholder
} from '../QuestionCard.styles';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';


const HelpOverlay = ({ question, helpVideoUrl, handleCloseNeedHelpModal }: { helpVideoUrl:any, question: Question, handleCloseNeedHelpModal: () => void }) => {
    let helpText = ""
    let sampleAnswer = <></>
    if (typeof question.sampleAnswer === 'string') {
      sampleAnswer = <ContentSubTitle>{question.sampleAnswer}</ContentSubTitle>;
      helpText = question.sampleAnswer;
    } else if (typeof question.sampleAnswer === 'object' && 'text' in question.sampleAnswer) {
      sampleAnswer = <ContentSubTitle>{question.sampleAnswer.text}</ContentSubTitle>;
      helpText = question.sampleAnswer.text;
    }
    return (
        <FeedbackModalOverlay>
<link
        href="//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.11.1/katex.min.css"
        rel="stylesheet"
    />
        <FeedbackModalWrapper>

          <FeedbackModalTopBar style={{ alignItems: 'center' }}>
            <ModalTitle>The below video will help you understand the concepts ...</ModalTitle>
            <CloseButton onClick={handleCloseNeedHelpModal}>X</CloseButton>
          </FeedbackModalTopBar>
          {helpVideoUrl?.url && <VideoClipPlaceholder>
            <video
              controls
              style={{ width: '100%', height: '100%', }}
            >
              <source src={helpVideoUrl.url} type="video/mp4" />
            </video>
          </VideoClipPlaceholder>}

          <ContentList style={{ margin: '1rem',display:!(helpText=="")?"block":"none" }}>
            <ContentTile>Sample Answer:</ContentTile>
            <ContentSubTitle>{sampleAnswer}</ContentSubTitle>
          </ContentList>
        </FeedbackModalWrapper>

      </FeedbackModalOverlay>
    )
}

export default HelpOverlay
