import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { toast } from 'react-toastify'
import PrivateRoutes from './components/PrivateRoute'
import MainLayout from './components/layout/MainLayout'
import AuthContext from './context/AuthContext'
import LogInPage from './pages/auth/AuthPage'
import SignUpPage from "./pages/auth/SignUpPage"
import { routes } from "./routes"

function handleSessionExpiry(logout: () => void) {
    return (error: any) => {
        console.log('event', error)
        if (error.status === 401 || error.status === 400) {
            toast.error("Your session has expired. Please login again!!!")
            logout()
        }
    }
}

const AppMain = () => {
    const [queryClient] = useState(() => new QueryClient({}))

    const { logout } = useContext(AuthContext);

    useEffect(() => {
        
        queryClient.getMutationCache().config.onError = handleSessionExpiry(logout)

        queryClient.getQueryCache().config.onError = handleSessionExpiry(logout)

    }, [logout, queryClient])



    return (
        <QueryClientProvider client={queryClient}>
            <Routes>
                <Route element={<PrivateRoutes />}>
                    <Route path="/" element={<MainLayout />}>
                        {routes}
                    </Route>
                </Route>
                <Route path="/login" element={<LogInPage />} />
                <Route path="/signup" element={<SignUpPage />} />
            </Routes>
        </QueryClientProvider>
    )
}

export default AppMain


