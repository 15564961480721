
const PlayCircleSvg = () => {
    return (
        <svg>
            <g opacity="0.39">
                <path d="M69 86.3334C58.8745 86.3334 50.6667 78.1256 50.6667 68.0001C50.6667 57.8746 58.8745 49.6667 69 49.6667C79.1255 49.6667 87.3333 57.8746 87.3333 68.0001C87.3333 78.1256 79.1255 86.3334 69 86.3334ZM76.5643 71.2011C76.9548 70.9206 77.2922 70.5631 77.5543 70.1469C78.6653 68.3777 78.2235 65.9852 76.5643 64.7991L69.1283 59.4861C68.542 59.0636 67.838 58.8354 67.1153 58.8334C65.1188 58.8334 63.5 60.5586 63.5 62.6871V73.3131C63.5 74.0776 63.7127 74.8237 64.1123 75.4581C65.2252 77.2272 67.471 77.6984 69.1302 76.5141L76.5643 71.2011ZM67.031 62.7384L74.4652 68.0514L67.0292 73.3626V62.7384H67.031Z" fill="white" />
            </g>
            <path d="M69 81.3334C61.636 81.3334 55.6667 75.3641 55.6667 68.0001C55.6667 60.6361 61.636 54.6667 69 54.6667C76.364 54.6667 82.3333 60.6361 82.3333 68.0001C82.3333 75.3641 76.364 81.3334 69 81.3334ZM74.5013 70.3281C74.7853 70.1241 75.0307 69.8641 75.2213 69.5614C76.0293 68.2747 75.708 66.5347 74.5013 65.6721L69.0933 61.8081C68.6669 61.5008 68.1549 61.3348 67.6293 61.3334C66.1773 61.3334 65 62.5881 65 64.1361V71.8641C65 72.4201 65.1547 72.9627 65.4453 73.4241C66.2547 74.7107 67.888 75.0534 69.0947 74.1921L74.5013 70.3281ZM67.568 64.1734L72.9747 68.0374L67.5667 71.9001V64.1734H67.568Z" fill="white" />
        </svg>
    )
}

export default PlayCircleSvg