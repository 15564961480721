import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import ChatBot from '../../components/common/Chatbot';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const Container = styled.div`
  background: #F8F9FA;
  min-height: 100vh;
  padding: 2rem;
`;

const StudyDeck = styled.section`
  background: #006ecc ;
  opacity: 80%;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
`;

const StudyDeckHeader = styled.h2`
  margin: 0;
  margin-bottom: 1rem;
  color: #FFFF;
`;

const StudyDeckContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const StudyTimeLeft = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: #FFFF;
`;

const ReviseButton = styled.button`
  padding: 1rem 2rem;
  font-weight: bold;
  opacity: 100%;
  border: none;
  border-radius: 20px;
  background: #FFFF;
  color: #006ecc;
  font-size: 1.2rem;
  cursor: pointer;
`;

const ClassesSection = styled.section`
  margin-bottom: 2rem;
`;

const SectionHeader = styled.h2`
  color: #4C4C4C;
  margin-bottom: 1rem;
`;
const SectionDescription = styled.p`
  margin: 4;
  font-weight: bold;
  font-size: 1rem;
  color: #0000FFF;
`;

const ClassList = styled.div`
  display: flex;
  gap: 1rem;
`;

const ClassCard = styled.div`
  background: #006ecc;
  opacity: 65%;
  padding: 1.5rem;
  border-radius: 10px;
  color: #FFFFFF;
  width: 150px;
  text-align: center;
`;

const UpcomingExams = styled.section`
  background: #FFFFFF;
  padding: 0.2rem;
  border-radius: 10px;
`;

const ExamTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background: #F2F2F2;
  }
`;

const TableCell = styled.td`
  padding: 1rem 0.5rem;
  text-align: left;
  border-bottom: 1px solid #DEE2E6;
`;

const ViewExamButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background: #78C2AD;
  color: #FFFFFF;
  cursor: pointer;
`;



const HomePage: React.FC = () => {

  const ChatBubbleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffbf00;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
`;

  const [isChatBotOpen, setChatBotOpen] = useState<boolean>(false);

  const handleOpenChatBot = () => {
    setChatBotOpen(true);
  };

  const handleCloseChatBot = () => {
    setChatBotOpen(false);
  };

  return (
    <Container>
      
      <StudyDeck>
        <StudyDeckHeader>Study Deck</StudyDeckHeader>
        <StudyDeckContent>
          <StudyTimeLeft>You have 0 hours 0 minutes of study left within your study deck.</StudyTimeLeft>
          <ReviseButton>Revise</ReviseButton>
        </StudyDeckContent>
      </StudyDeck>

      <ClassesSection>
        <SectionHeader>Latest Classes</SectionHeader>
        <SectionDescription>Quick access to latest viewed class</SectionDescription>
        <ClassList>
          <ClassCard>Exchanging Data</ClassCard>
          <ClassCard>HTML CSS</ClassCard>
          <ClassCard>REACT NATIVE</ClassCard>
        </ClassList>
      </ClassesSection>

      <UpcomingExams>
        <SectionHeader>Upcoming Exams</SectionHeader>
        <ExamTable>
          <tbody>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>Computer Science</TableCell>
              <TableCell>48 hours</TableCell>
              <TableCell><ViewExamButton>View Exam</ViewExamButton></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>English</TableCell>
              <TableCell>12/04/2024 (4 days)</TableCell>
              <TableCell><ViewExamButton>View Exam</ViewExamButton></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>Spanish</TableCell>
              <TableCell>12/04/2024 (4 days)</TableCell>
              <TableCell><ViewExamButton>View Exam</ViewExamButton></TableCell>
            </TableRow>
          </tbody>
        </ExamTable>
      </UpcomingExams>

      <ChatBot isOpen={isChatBotOpen} handleClose={handleCloseChatBot} />

      <ChatBubbleButton onClick={handleOpenChatBot}>
        <ChatBubbleIcon style={{ color: 'white', fontSize: '24px' }} />
      </ChatBubbleButton>

    </Container>
  );
};

export default HomePage;
 