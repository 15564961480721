import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ChatBot from '../../components/common/Chatbot';
import { Module, Topic } from '../../interfaces/module';
import { useGetDisciplines } from '../../services/classes';
const Container = styled.div`
    background: #F8F9FA;
    min-height: '100%';
    padding: 2rem;
`;


const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
`;

const MainTitle = styled.h2`
    margin: 0;
`;

// const Mainheading = styled.p`
//     margin: 0;
//     font-size: 1.2rem;
//     margin-left: 10px
// `;

const ClassSection = styled.section`
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background: #FFFF;
    overflow: hidden;
`;

const ClassColor = styled.div<{ color: string }>`
    width: 150px;
    background-color: ${props => props.color};
    
`;

const ClassDeck = styled.section`
    opacity: 90%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%
`;


const ClassDeckHeader = styled.h2`
    margin: 0;
    justify-content: space-between;
    display: flex;
`;
const GradeButton = styled.button<{ color: string }>`
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    opacity: 100%;
    border: none;
    border-radius: 10px;
    background: ${props => props.color};
    color: #FFF;
    font-size: 1rem;
    margin: 0;
`;

const ProgressText = styled.p`
    margin: 0;
    font-size: 0.8rem;
`;

const ProgressBarContainer = styled.div`
    width: 80%;  
    height: 16PX; 
    background-color: #DEE2E6;
`;

const ProgressBar = styled.div<{ progress: number, color: string }>`
    height: 100%;
    width: ${props => props.progress}%;
    background-color: ${props => props.color};
`;

const ButtonSection = styled.section`
    display: flex;
    gap: 0.6rem;
`;

const Buttons = styled.button<{ color: string }>`
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    opacity: 100%;
    border: none;
    border-radius: 6px;
    background: ${props => props.color};
    color: #FFF;
    font-size: 16;
    cursor: pointer;
`;

const AllClasses: React.FC = () => {

    const ChatBubbleButton = styled.button`
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #ffbf00;
        border: none;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 10;
    `;


    const { data: classes, isLoading } = useGetDisciplines();

    const [isChatBotOpen, setChatBotOpen] = useState<boolean>(false);

    const handleOpenChatBot = () => {
        setChatBotOpen(true);
    };

    const handleCloseChatBot = () => {
        setChatBotOpen(false);
    };

    const navigate = useNavigate();

    const handleLearn = (topics: Topic[], originalClassId: string): void => {
        navigate('/myclasses/learn', {
            state: {
                topics: topics,
                originalClassId: originalClassId
            }
        });
    };

    const handleTryExamQuestions = (topics: Topic[], originalClassId: string): void => {
        navigate('/Class/Questions', {
            state: {
                topics: topics,
                originalClassId: originalClassId,
            }
        });
    };

    const showTopic = (topics: Topic[], className: string,originalClassId:string) => {
        navigate('/Class/Topics/'+originalClassId, {
            state: {
                topics: topics,
                className: className,
                originalClassId: originalClassId
            }
        });
    }
    if (isLoading) {
        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
                <CircularProgress />
            </Box>
        )
    }

    if (!classes || classes.length === 0) {
        return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
            <Box justifyContent={'center'} alignContent={'center'} display={'flex'} flexDirection={'column'}>
                <p> You havent enrolled to any classes yet. </p>
                <GradeButton color='#5227CC;' style={{ cursor: 'pointer' }} onClick={() => navigate("/enroll")}>Enroll</GradeButton>
            </Box>
        </Box>
    }
    return (
        <Container>
            <TitleSection>
                <MainTitle>Your classes</MainTitle>
                {/*<Mainheading>Here are all the classes you are enrolled in</Mainheading>*/}
            </TitleSection>

            {classes?.map((classItem: Module) => (
                <ClassSection key={classItem._id} >
                    <ClassColor color={'purple'} />
                    <ClassDeck>
                        <ClassDeckHeader>
                            {classItem.Name}
                            <GradeButton color={'purple'}>{classItem.grade}</GradeButton>
                        </ClassDeckHeader>
                        <ProgressText>Your Progress in this class</ProgressText>
                        <ProgressBarContainer>
                            <ProgressBar progress={classItem.progress} color={'purple'} />
                        </ProgressBarContainer>
                        <ButtonSection>
                            {/* <Buttons onClick={() => handleLearn(classItem.topics, classItem.originalClassId)} color="#7749F8">Learn</Buttons> */}
                            <Buttons onClick={() => showTopic(classItem.topics, classItem.Name,classItem.originalClassId)} color="#7749F8">Learn</Buttons>

                            
                            <Buttons onClick={() => handleTryExamQuestions(classItem.topics, classItem.originalClassId)} color="#00A3FF">Attempt Questions</Buttons>
                            <Buttons color="#009420">Try Exam</Buttons>
                        </ButtonSection>
                    </ClassDeck>
                </ClassSection>
            ))}


            <ChatBot isOpen={isChatBotOpen} handleClose={handleCloseChatBot} />

            <ChatBubbleButton onClick={handleOpenChatBot}>
                <ChatBubbleIcon style={{ color: 'white', fontSize: '24px' }} />
            </ChatBubbleButton>

        </Container>
    );
};

export default AllClasses;
