import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ChatBot from '../../components/common/Chatbot';
import { ContentData, Module } from '../../interfaces/module';
import { useEnrollInClass } from '../../services/classes';
import { Box, CircularProgress } from '@mui/material';

const Container = styled.div`
    background: #F8F9FA;
    min-height: 100vh;
    padding: 2rem;
`;

const BackButton = styled.button`
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    opacity: 100%;
    border: none;
    border-radius: 6px;
    background: #7749F8;
    color: #FFF;
    font-size: 16;
    cursor: pointer;
`;

const HeaderSection = styled.section`
    display: flex;
    flex-direction: column;
`;

const TitleSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MainTitle = styled.h2`
    margin: 0;
`;

const Mainheading = styled.p`
   margin: 0;
   font-size: 0.9rem;
   font-weight: bold;
`;

const TopicSection = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background: #FFFF;
    overflow: hidden;
`;

const TopicDeckTitle = styled.section`
    margin-top: 0;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    display: flex;
    font-size: 20;
    font-weight: bold;
    background-color: #F8F9FA;
`;
const TopicDeck = styled.section`
    opacity: 90%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%
`;


const TopicDeckHeader = styled.h2`
    margin-top: 0;
    justify-content: space-between;
    display: flex;
    font-size: 1rem;
`;

const ProgressInput = styled.input`
    width: 100%;
    margin-top: 8px;
    color: red;
`;



const ButtonSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    max-width: 50rem;
    margin: auto;
`;

const Buttons = styled.button<{ color: string, TextColor: string }>`
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    opacity: 100%;
    border: none;
    border-radius: 6px;
    background: ${props => props.color};
    color: ${props => props.TextColor};
    font-size: 16;
    cursor: pointer;
`;

const EnrollRAGPage: React.FC = () => {

    const ChatBubbleButton = styled.button`
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #ffbf00;
        border: none;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 10;
    `;

    const [isChatBotOpen, setChatBotOpen] = useState<boolean>(false);

    const handleOpenChatBot = () => {
        setChatBotOpen(true);
    };

    const handleCloseChatBot = () => {
        setChatBotOpen(false);
    };

    const navigate = useNavigate();

    const { mutate, isSuccess: isEnrollSuccess, isPending: isLoading } = useEnrollInClass();

    const handleEnroll = (): void => {
        const identifier = Cookies.get('access_token') || '';
        const updatedModule: ContentData = {
            ...module,
            topics: module.topics.map(topic => ({
                ...topic,
                progress: progress[topic.topic]
            })),
            progress: Math.round(Object.values(progress).reduce((acc, curr) => acc + curr, 0) / module.topics.length),
            identifier
        };
        mutate(updatedModule);

    };

    useEffect(() => {
        if (isEnrollSuccess) {
            navigate('/myclasses/All-Classes');
        }
    }, [isEnrollSuccess, navigate])



    const handleBack = (): void => {
        navigate(`/enroll/subject-information/${encodeURIComponent(module._id)}`, { state: { module } });
    };

    const handleNoEnroll = (): void => {
        navigate('/home');
    };

    const location = useLocation();
    const { module } = location.state as { module: Module };

    const [progress, setProgress] = useState<Record<string, number>>(
        module?.topics.reduce((acc, topic) => {
            acc[topic.topic] = topic.progress;
            return acc;
        }, {} as Record<string, number>) || {}
    );

    const handleProgressChange = (topic: string, newValue: number) => {
        setProgress(prev => ({ ...prev, [topic]: newValue }));
    };

    if (!module) {
        return <div>No module selected</div>;
    }


    if (isLoading) {
        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container>
            <TitleSection>
                <MainTitle>{module.Name}</MainTitle>
                <BackButton onClick={handleBack}>Back</BackButton>
            </TitleSection>

            <HeaderSection>
                <Mainheading>Please drag the bar for how much you know on the selected topics</Mainheading>
            </HeaderSection>

            {module.topics.map((topic, index) => (
                <TopicSection key={index}>
                    <TopicDeckTitle>{topic.topic}</TopicDeckTitle>
                    <TopicDeck>
                        <div>
                            <ProgressInput
                                type="range"
                                min="0"
                                max="100"
                                color='red'
                                value={progress[topic.topic] || 0}
                                onChange={(e) =>
                                    handleProgressChange(topic.topic, Number(e.target.value))
                                }
                            />
                        </div>
                    </TopicDeck>
                </TopicSection>
            ))}


            <ButtonSection>
                <Buttons color="#7749F8" TextColor="#FFF" onClick={handleEnroll}>
                    Finish and Enroll
                </Buttons>
                <Buttons color="#F8F9FA" TextColor="#7749F8" onClick={handleNoEnroll}>
                    I don't want to enroll anymore
                </Buttons>
            </ButtonSection>
            <ChatBot isOpen={isChatBotOpen} handleClose={handleCloseChatBot} />

            <ChatBubbleButton onClick={handleOpenChatBot}>
                <ChatBubbleIcon style={{ color: 'white', fontSize: '24px' }} />
            </ChatBubbleButton>
        </Container>
    );
};

export default EnrollRAGPage;

