import styled from "styled-components";
import sizeConfigs from "../../configs/sizeConfigs";

const QuestionSection = styled.section`
    display: flex;
    position: relative;
    flex-direction: row;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-radius: 10px;h
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background: #FFFF;
    overflow: visible;
`;


const ClassDeck = styled.section`
    opacity: 90%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%
`;


const QuestionTop = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const QuestionDeckHeader = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 1.3rem; 
    justify-content: space-between;
    display: flex;
`;

const QuestionDeckTopRightItems = styled.section`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items:center
`;

const QuestionDeckFavAndButton = styled.section`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

const QuestionDeckMarks = styled.section`
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
`;


const QuestionDeckDifficulty = styled.section`
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    height:fit-content;
    padding-top:0.4rem;
    padding-bottom:0.4rem; 
    padding-left:1.5rem;
    padding-right:1.5rem;
    border-radius: 1rem;
`;


const QuestionDeckChatBot = styled.button<{ color: string, TextColor: string }>`
    font-weight: bold;
    margin-top: 5px;
    display: flex;
    opacity: 100%;
    border: none;
    border-radius: 6px;
    background: ${props => props.color};
    color: ${props => props.TextColor};
    font-size: 15;
    cursor: pointer;
`;

const QuestionInput = styled.textarea`
    min-height: 125px;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    resize: none;
    line-height: 1.5;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease-in-out;

    &:focus {
        outline: none;
        box-shadow: 0px 0px 8px rgba(0, 110, 204, 1);
        + div {
            display: block;
        }
    }

    &::placeholder {
        color: #999;
    }
`;



const ButtonSection = styled.section`
    display: flex;
    gap: 0.6rem;
`;

const Buttons = styled.button<{ color: string, TextColor: string }>`
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    opacity: 100%;
    border: none;
    border-radius: 6px;
    background: ${props => props.color};
    color: ${props => props.TextColor};
    font-size: 16;
    cursor: pointer;
`;

const SubmitButton = styled.button<{ color: string, TextColor: string, isSubmitting: boolean }>`
  padding: 0.5rem 0.7rem;
  font-weight: bold;
  opacity: 100%;
  border: none;
  border-radius: 6px;
  font-size: 16;
  cursor: ${(props) => (props.isSubmitting ? '' : 'pointer')};
  font-weight: bold;
  color: ${props => props.TextColor};
  background: ${(props) => (props.isSubmitting ? '#003366' : props.color)};
`;

const WavingImageContainer = styled.div`
    position: absolute;
    top: 53px; 
    right: -12px;
    width: 150px;
    z-index: 5; 
`;

const WavingImage = styled.img`
    width: 90px; 
    height: 65px;
    display: block;
    margin: 0 auto 10px;
`;

const AnsweredModal = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const AnsweredModalContent = styled.div`
    padding-left: 6rem;
    padding-right: 6rem;
    color: #FFF;
    border-radius: 10px;
    text-align: left;
    width: 100%;
`;

const AnsweredModalTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
`;
const AnsweredModalHeader = styled.div`
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
`;

const AnsweredModalBody = styled.div`
    font-size: 11px;
`;

const AnsweredModalFooter = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    
    gap: 1rem;
`;

const TryAgainButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    color: white;
    background-color: #006ecc; 
`;

const FullFeedbackButton = styled.button<{ color: string, isFeedbackProcessing: boolean }>`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: ${(props) => (props.isFeedbackProcessing ? '' : 'pointer')};
    font-weight: bold;
    color: white;
    background-color: ${(props) => (props.isFeedbackProcessing ? '#003366' : props.color)};
`;

const FeedbackModalOverlay = styled.div`
  background: #F8F9FA;
  position: fixed;
  top: ${sizeConfigs.topbar.height};
  left: ${sizeConfigs.sidebar.width};
  right: 0;
  bottom: 0;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  padding-bottom: 2rem;
`;

const FeedbackModalWrapper = styled.div`
  background-color: colorConfigs.mainBg;
  width: 100%;
  max-height: calc(94vh - ${sizeConfigs.topbar.height} - 2rem);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1001;
  margin-top: 20px;
  position: relative;
`;

const FeedbackModalTopBar = styled.div`
  font-size: 1.2rem;
  background-color: #f0f0f0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const FeedbackModalContainer = styled.div`
  padding: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

const FeedbackModalHeader = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const CloseButton = styled.button`
  // align-self: flex-end;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  background: #ccc;
  border: none;
  border-radius: 5px;

  &:hover {
    background: #bbb;
  }
`;


const ReviseButton = styled.p`
  width:100%;
  text-align:left;
  align-self: flex-end;
  cursor: pointer;
  border-radius: 5px;
  border-color:'#ccc';
`;


const ModalHeading = styled.h3`
  margin-bottom: 0.5rem;
`;

const Subheading = styled.p`
  font-size:16px;
  line-height:18px;

`;

const ContentList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ContentListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const VideoClipPlaceholder = styled.div`
  background-color: #ddd;
  display: flex;
  height: 400px;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
`;


const MoreDepthFeedback = styled.div`
  gap: 1rem;
  padding-bottom: 10px;
`;

const FeedbackCard = styled.div<{ color: string }>`
  gap: 1rem;
  padding: 1rem;
  background-color: #eee;
  color: black;
  border-left: 4px solid ${props => props.color};
`;


const ActionTipSpan = styled.span`
  gap: 1rem;
  padding: 0.2rem;
  // background-color: #eee;
  color: black;
  font-size:0.8rem;
`;

const ModalTitle = styled.p`
  font-weight:bold;
`
const ContentTile = styled.p`
font-weight:bold;
`

const ContentSubTitle = styled.p`
  margin-top: '1rem';
`


export {
  AnsweredModal, AnsweredModalBody, AnsweredModalContent, AnsweredModalFooter,
  AnsweredModalHeader, AnsweredModalTitle, ButtonSection, Buttons, ClassDeck, CloseButton, ContentList,
  ContentListItem, FeedbackCard, FeedbackModalContainer, FeedbackModalHeader, FeedbackModalOverlay, FeedbackModalTopBar,
  FeedbackModalWrapper, FullFeedbackButton, ModalHeading, MoreDepthFeedback, QuestionDeckChatBot,
  QuestionDeckDifficulty, QuestionDeckFavAndButton, QuestionDeckHeader, QuestionDeckMarks,
  QuestionDeckTopRightItems, QuestionInput, QuestionTop, QuestionSection, WavingImageContainer, WavingImage,
  TryAgainButton, SubmitButton, VideoClipPlaceholder, ReviseButton, Subheading, ActionTipSpan, ModalTitle, ContentTile, ContentSubTitle
}