import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Feedback, { Datum } from '../../../interfaces/Feedback';
import { Question } from '../../../interfaces/Question';
import { useSendReviseLater } from '../../../services/revision';
import {
    ContentList,
    ModalHeading
} from '.././QuestionCard.styles';


const Subheading = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 16px;
  
`;

const TextWrapper = styled.div`
  p {
    margin: 0;
  }
        display: flex;
    flex-direction: column;
    // gap: 0.5rem;
    gap:8px;
`;

const Title = styled.p<{ isSuccess: boolean }>`
  font-size: 16px;
  font-weight: 600;
  line-height:19.38px;
  color: ${({ isSuccess }) => (isSuccess ? '#fff' : '#000')};
`;

const Subtitle = styled.p<{ isSuccess: boolean }>`
  color: ${({ isSuccess }) => (isSuccess ? '#fff' : '#6C757D')};
  font-weight:400;
  font-size:16px;
  line-height:28px;
`;

const IconWrapper = styled.div``;


const RevisionListContainer = styled.div<{ issuccess: boolean }>`
  background-color: ${({ issuccess }) => (issuccess ? '#9e01fd' : '#fff')};
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between; 
  align-items: center;
  border: 1px solid #DEE2E6;
  cursor: pointer;
`;


const ReviseComponent = ({ feedback, question }: { feedback: Feedback, question: Question }) => {



    const { mutate: sendReviseLater, data: reviseLaterResponse } = useSendReviseLater();

    const [focusedRevisionContent, setFocusedRevisionContent] = useState('')
    const [revisionLaterSuccess, setRevisionLaterSuccess] = useState<{ [key: string]: boolean }>({});


    useEffect(() => {
        console.log('reviseLaterResponse', reviseLaterResponse)
        if (typeof (reviseLaterResponse) !== "undefined") {
            setRevisionLaterSuccess(rev => ({
                ...rev,
                [focusedRevisionContent]: reviseLaterResponse?.ok
            }));


        }
    }, [focusedRevisionContent, reviseLaterResponse])

    const addToRevision = (content: Datum, question: Question) => {

        console.log(`add the content : ${content.contentToLearn} to revise later`)

        const payload = {
            qID: question._id,
            discipline: question.discipline,
            message: content.contentToLearn,
            time: content.timeExpectedToLearn,
            ContentName: question.ContentName,
            subtopic: question.subtopic,
            topic: question.topic,
        }

        setFocusedRevisionContent(content.contentToLearn.replaceAll(" ", ""));
        sendReviseLater(payload);
    }


    return (

        <ContentList>
            <ModalHeading>List of topics for revision:</ModalHeading>
            <Subheading>Tap on what you want to save for later:</Subheading>

            {feedback?.data.map((content,index) =>
                <RevisionListContainer key={index} onClick={() => {
                    if (!revisionLaterSuccess[content.contentToLearn.replaceAll(" ", "")]) {
                        addToRevision(content, question)
                    } else {
                        console.log('already saved to revise later')
                    }
                }}
                    issuccess={revisionLaterSuccess[content.contentToLearn.replaceAll(" ", "")]}>
                    <TextWrapper>
                        <Title isSuccess={revisionLaterSuccess[content.contentToLearn.replaceAll(" ", "")]}>
                            {content.contentToLearn}
                        </Title>
                        <Subtitle isSuccess={revisionLaterSuccess[content.contentToLearn.replaceAll(" ", "")]}>
                            Expected time to complete: {content.timeExpectedToLearn} minutes
                        </Subtitle>
                    </TextWrapper>
                    <IconWrapper>
                        {revisionLaterSuccess[content.contentToLearn.replaceAll(" ", "")] ? <StarIcon style={{ color: '#ffe600' }} /> : <StarBorderIcon style={{ color: '#c4c4c4' }} />}
                    </IconWrapper>
                </RevisionListContainer>

            )}
        </ContentList>
    )
}

export default ReviseComponent