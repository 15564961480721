import React, { useContext } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const SignupSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F8F9FA;
  min-height: 100vh;
  padding: 2rem;
`;

const AuthSection = styled.section`
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 500px;
`;

const AuthTitle = styled.h2`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-bottom: 0rem;
  gap: 2rem;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const Label = styled.label`
  position: absolute;
  left: 0px;
  top: -25px;
  font-size: 15px;
`;

const Input = styled.input`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  font-size: 13px;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
`;

const SignupButton = styled.button`
  background-color: #8A2BE2;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  width: 132px;
  height: 50px;
`;

const OptionSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const LinkButton = styled.button`
  background: none;
  color: #8A2BE2;
  text-decoration: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

interface FormValues {
    email: string;
    password: string;
    school: string;
    Fname: string;
    code: string;
  }

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string().required('Required'),
  school: Yup.string().required('Required'),
  Fname: Yup.string().required('Required'),
});

const DashboardPage = () => {
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
      school: '',
      Fname: '',
      code: '',
    },
    validationSchema,
    onSubmit: async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        console.log(values)
        await signup(values);
    },
  });

  const handleForgotPasswordPress = () => {
    console.log("Forgot Password pressed");
  };

  const handleSignUpPress = () => {
    navigate("/login");
  };

  return (
    <SignupSection>
      <AuthSection>
        <AuthTitle>Student Registration</AuthTitle>
        <Form onSubmit={formik.handleSubmit}>
          <InputGroup>
            <Input
              id="Fname"
              name="Fname"
              type="text"
              placeholder='First Name'
              value={formik.values.Fname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Label htmlFor="Fname">First Name</Label>
            {formik.touched.Fname && formik.errors.Fname ? <div>{formik.errors.Fname}</div> : null}
          </InputGroup>
          <InputGroup>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder='Email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Label htmlFor="email">Email</Label>
            {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}
          </InputGroup>
          <InputGroup>
            <Input
              id="school"
              name="school"
              type="text"
              placeholder='School'
              value={formik.values.school}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Label htmlFor="school">School</Label>
            {formik.touched.school && formik.errors.school ? <div>{formik.errors.school}</div> : null}
          </InputGroup>
          <InputGroup>
            <Input
              id="password"
              name="password"
              type="password"
              placeholder='Password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Label htmlFor="password">Password</Label>
            {formik.touched.password && formik.errors.password ? <div>{formik.errors.password}</div> : null}
          </InputGroup>
          <OptionSection>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              Remember me
            </CheckboxLabel>
            <LinkButton type="button" onClick={handleForgotPasswordPress}>Forgot password?</LinkButton>
          </OptionSection>
          <ButtonSection>
            <SignupButton type="submit">Sign Up</SignupButton>
            <p style={{ textAlign: 'center' }}><i>Already have an account?</i><br /><LinkButton onClick={handleSignUpPress}><strong>LOG IN</strong></LinkButton></p>
          </ButtonSection>
        </Form>
      </AuthSection>
    </SignupSection>
  );
};

export default DashboardPage;
