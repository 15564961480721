import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import ChatBot from '../../components/common/Chatbot';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Module } from '../../interfaces/module';


const Container = styled.div`
    background: #F8F9FA;
    min-height: 100vh;
    padding: 2rem;
`;

const BackButton = styled.button`
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    opacity: 100%;
    border: none;
    border-radius: 6px;
    background: #7749F8;
    color: #FFF;
    font-size: 16;
    cursor: pointer;
`;
const TitleSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MainTitle = styled.h2`
    margin: 0;
`;

const Mainheading = styled.p`
    margin: 0;
    font-size: 1rem;
`;

const ClassSection = styled.section`
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 10px;h
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background: #FFFF;
    overflow: hidden;
`;

const ClassColor = styled.div<{ color: string }>`
    width: 150px;
    background-color: ${props => props.color};
`;

const ClassDeck = styled.section`
    opacity: 90%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%
`;


const ClassDeckHeader = styled.h2`
    margin: 0;
    justify-content: space-between;
    display: flex;
`;

const DescriptionText = styled.p`
    margin: 0;
    font-size: 0.8rem;
`;


const ButtonSection = styled.section`
    display: flex;
    gap: 0.6rem;
`;

const Buttons = styled.button<{ color: string }>`
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    opacity: 100%;
    border: none;
    border-radius: 6px;
    background: ${props => props.color};
    color: #FFF;
    font-size: 16;
    cursor: pointer;
`;

const ModuleSection = styled.section`
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    margin-top: 1rem;
    border-radius: 12px;
`;

const ModuleTitle = styled.button<{ isOpen?: boolean }>`
    background: #FFFF;
    color: #333333;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
        background-color: #f7f7f7;
        border-radius: ${props => props.isOpen ? "0" : "12px"};
      }
`;

const ChatBubbleButton = styled.button`
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #ffbf00;
        border: none;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 10;
    `;

const ModuleInfo = styled.div<{ isOpen: boolean }>`
    overflow: hidden;
    max-height: ${props => (props.isOpen ? "1000px" : "0")};
    padding: ${props => (props.isOpen ? "10px" : "0 18px")};
    opacity: 60%;
`;


const ModuleWrapper = styled.div<{ isLast?: boolean, isOpen?: boolean }>`
  &:last-child {
    > ${ModuleTitle} {
      border-bottom-left-radius: ${props => props.isOpen ? '0' : '12px'};
      border-bottom-right-radius: ${props => props.isOpen ? '0' : '12px'};
    }

    > ${ModuleInfo} {
      border-bottom-left-radius: ${props => props.isOpen ? '0' : '12px'};
      border-bottom-right-radius: ${props => props.isOpen ? '0' : '12px'};
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #E7E7E7; 
  }
  > ${ModuleTitle} {
    border-bottom: ${props => props.isOpen ? '1px solid #E7E7E7' : 'none'};
  }
`;


const EnrollInfoPage: React.FC = () => {
    const location = useLocation(); 
    const { module } = location.state as { module: Module };
    const [openModules, setOpenModules] = useState<Record<string, boolean>>({});

    const toggleModule = (moduleName: string): void => {
        setOpenModules(prevState => ({
            ...prevState,
            [moduleName]: !prevState[moduleName]
        }));
    };

    const [isChatBotOpen, setChatBotOpen] = useState<boolean>(false);

    const handleOpenChatBot = () => {
        setChatBotOpen(true);
    };

    const handleCloseChatBot = () => {
        setChatBotOpen(false);
    };

    const navigate = useNavigate();

    const handleEnroll = (): void => {
        navigate(`/enroll/RAG/${encodeURIComponent(module._id)}`, { state: { module } });
    };

    const handleBack = (): void => {
        navigate('/enroll');
    };

    return (
        <Container>
            <TitleSection>
                <MainTitle>{module.Name}</MainTitle>
                <BackButton onClick={handleBack}>Back</BackButton>
            </TitleSection>

            <Mainheading>view the modules of the {module.Name} class</Mainheading>

            <ClassSection>
                <ClassColor color="#A000FE" />
                <ClassDeck>
                    <ClassDeckHeader>Class Description</ClassDeckHeader>
                    <DescriptionText>{module.level} - {module.board}</DescriptionText>
                    <ButtonSection>
                        <Buttons color="#7749F8" onClick={handleEnroll}>Enroll in Class</Buttons>
                        <Buttons color="#00A3FF">View Example</Buttons>
                    </ButtonSection>
                </ClassDeck>
            </ClassSection>

            <TitleSection>
                <MainTitle>Modules</MainTitle>
            </TitleSection>

            <ModuleSection>
                {module.topics?.map((topic: any, index: number) => (
                    <ModuleWrapper
                        key={topic.topic}
                        isOpen={!!openModules[topic.topic]}
                    >
                        <React.Fragment key={topic.topic}>
                            <ModuleTitle
                                onClick={() => toggleModule(topic.topic)}
                                isOpen={openModules[topic.topic]}
                                style={{
                                    borderTopLeftRadius: index === 0 ? '12px' : '0',
                                    borderTopRightRadius: index === 0 ? '12px' : '0',
                                    borderBottomLeftRadius: index === module.topics.length - 1 && !openModules[topic.topic] ? '12px' : '0',
                                    borderBottomRightRadius: index === module.topics.length - 1 && !openModules[topic.topic] ? '12px' : '0',
                                }}
                            >
                                {topic.topic}
                                {openModules[topic.topic] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ModuleTitle>
                            <ModuleInfo isOpen={!!openModules[topic.topic]}>
                                {topic.subtopics?.map((subtopic: any, subIndex: number) => (
                                    <DescriptionText key={subIndex}>
                                        {subtopic.subtopic}
                                    </DescriptionText>
                                ))}                            
                                </ModuleInfo>
                        </React.Fragment>
                    </ModuleWrapper>
                ))}
            </ModuleSection>

            <ChatBot isOpen={isChatBotOpen} handleClose={handleCloseChatBot} />
            <ChatBubbleButton onClick={handleOpenChatBot}>
                <ChatBubbleIcon style={{ color: 'white', fontSize: '24px' }} />
            </ChatBubbleButton>
        </Container>
    );

};

export default EnrollInfoPage;
