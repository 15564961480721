import { uniqueId } from 'lodash';
import { ReactElement } from 'react';
import { generateColorMatrix } from './converter';


function generateUniqueFilterId(id: number) {
    return uniqueId(`filter-${id}`);
}

const SvgWrapper = ({ color, icon }: { color: string, icon?: ReactElement }) => {
    const matrix = generateColorMatrix(color)

    const filter_1 = generateUniqueFilterId(1)
    const filter_2 = generateUniqueFilterId(2)
    const filter_3 = generateUniqueFilterId(3)
    const filter_4 = generateUniqueFilterId(4)
    const filter_5 = generateUniqueFilterId(5)


    return (
        <svg width="137" height="121" viewBox="0 0 137 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter={"url(#" + filter_1 + ")"}>
                <rect x="23.0975" y="68.3804" width="64.5637" height="64.5637" rx="6" transform="rotate(-45 23.0975 68.3804)" fill={color} />
                <rect xmlns="http://www.w3.org/2000/svg" x="21.4762" y="67.6051" width="67.5637" height="67.5637" rx="7.5" transform="rotate(-45 21.4762 67.6051)" stroke="white" stroke-opacity="0.2" stroke-width="3" shape-rendering="crispEdges" />
            </g>

            {/* middle icon STARTS*/}
            {icon}
            {/* middle icon ENDS*/}

            <g filter={"url(#" + filter_2 + ")"}>
                <rect x="0.500061" y="90.7271" width="27.6864" height="27.6864" rx="6" transform="rotate(-45 0.500061 90.7271)" fill={color} />
            </g>
            <g filter={"url(#" + filter_3 + ")"}>
                <rect x="27.6168" y="20.2485" width="27.5392" height="27.5392" rx="6" transform="rotate(-45 27.6168 20.2485)" fill={color} />
            </g>
            <g filter={"url(#" + filter_4 + ")"}>
                <rect x="71.5202" y="20.2485" width="27.5392" height="27.5392" rx="6" transform="rotate(-45 71.5202 20.2485)" fill={color} />
            </g>
            <g filter={"url(#" + filter_5 + ")"}>
                <rect x="97.3455" y="90.7271" width="27.6864" height="27.6864" rx="6" transform="rotate(-45 97.3455 90.7271)" fill={color} />
            </g>
            <defs>
                <filter id={filter_1} x="25.5827" y="25.2122" width="86.3363" height="95.3364" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="9" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values={matrix} />

                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_772" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_772" result="shape" />
                </filter>
                <filter id={filter_2} x="2.98535" y="73.635" width="34.1839" height="43.1841" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="9" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values={matrix} />

                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_772" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_772" result="shape" />
                </filter>
                <filter id={filter_3} x="30.1021" y="3.26074" width="33.9757" height="42.9756" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="9" />
                    <feComposite in2="hardAlpha" operator="out" />
                    {/* <feColorMatrix type="matrix" values="0 0 0 0 0.466667 0 0 0 0 0.286275 0 0 0 0 0.972549 0 0 0 0.25 0" /> */}
                    <feColorMatrix type="matrix" values={matrix} />

                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_772" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_772" result="shape" />
                </filter>
                <filter id={filter_4} x="74.0054" y="3.26074" width="33.9757" height="42.9756" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="9" />
                    <feComposite in2="hardAlpha" operator="out" />
                    {/* <feColorMatrix type="matrix" values="0 0 0 0 0.466667 0 0 0 0 0.286275 0 0 0 0 0.972549 0 0 0 0.25 0" /> */}
                    <feColorMatrix type="matrix" values={matrix} />

                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_772" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_772" result="shape" />
                </filter>
                <filter id={filter_5} x="99.8308" y="73.635" width="34.1839" height="43.1841" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="9" />
                    <feComposite in2="hardAlpha" operator="out" />
                    {/* <feColorMatrix type="matrix" values="0 0 0 0 0.466667 0 0 0 0 0.286275 0 0 0 0 0.972549 0 0 0 0.25 0" /> */}
                    <feColorMatrix type="matrix" values={matrix} />

                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_772" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_772" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export default SvgWrapper

