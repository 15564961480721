import { AppBar, Toolbar, Typography, Box, Avatar, Menu, MenuItem } from "@mui/material";
import { useState, useContext } from "react";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import AuthContext from "../../context/AuthContext";

const Topbar = () => {
  const { logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif', 
            fontWeight: 800, 
          }}
        >
          LogicLand
        </Typography>

        <Box sx={{ flexGrow: 1 }}></Box>

        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={handleMenuClick}
          aria-controls="profile-menu"
          aria-haspopup="true"
        >
          <Avatar sx={{ width: 40, height: 40, marginRight: '12px' }}> 
            JG
          </Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column', lineHeight: '1.2' }}>
            <Typography variant="subtitle1" component="div" sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
              Joe Gosling
            </Typography>
            <Typography variant="caption" component="div" sx={{ fontSize: '0.875rem', color: 'text.secondary' }}>
              Student
            </Typography>
          </Box>
        </Box>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: 48,
              width: '20ch',
            },
          }}
        >
          <MenuItem onClick={handleLogout}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
