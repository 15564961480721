import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#FFFF",
    color: "#605c5c",
    hoverBg: "#f0ecec",
    activeBg: "#c0bdbd",
    activeColor: '#006ecc'
  },
  topbar: {
    bg: "#FFFF",
    color: "#006ecc"
  },
  footer: {
    bg: "#FFFF",
    color: "gray",
    borderColor: "#0000FFF"
  },
  mainBg: colors.grey["100"]
};

export default colorConfigs;