import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContext from '../context/AuthContext';

const PrivateRoutes: React.FC = () => {

  const [authTokens] = useState( Cookies.get('access_token'));
  const { logout } = useContext(AuthContext)

  useEffect(() => {
    if (!authTokens) {
      toast.error("Your session has expired. Please login again!!!")
      logout()
    }
  }, [authTokens, logout])
  
  


 

  return !authTokens ? <Navigate to="/login" /> : <Outlet />;
};

export default PrivateRoutes;
