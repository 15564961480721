import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";

const Newsletter = () => {
  return (
    <Box bgcolor={'white'} borderRadius={'.75rem'} p={'1.5rem'}>
      <Typography fontWeight={600} fontSize={'1.5rem'} lineHeight={'1.8156rem'}>Newsletter</Typography>
      <FormGroup sx={{ p: '1rem' }}>
        <FormControlLabel control={<Checkbox size="small" sx={{
          '&.Mui-checked': {
            color: '#A000FE',
          },
        }}
        />} label="Get exam reminder emails" />
        <FormControlLabel control={<Checkbox size="small" sx={{
          '&.Mui-checked': {
            color: '#A000FE',
          },
        }} />} label="Get fun fact of the day emails" />
        <FormControlLabel control={<Checkbox size="small" sx={{
          '&.Mui-checked': {
            color: '#A000FE',
          },
        }} />} label="Other stuff" />
      </FormGroup>
    </Box>
  );
};

export default Newsletter;
