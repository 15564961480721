import { EmailOutlined } from "@mui/icons-material";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";

const ChangeEmail = () => {
  return (
    <Box bgcolor={'white'} borderRadius={'.75rem'} p={'1.5rem'}>
      <Typography fontWeight={600} fontSize={'1.5rem'} lineHeight={'1.8156rem'}>Account Settings</Typography>
      <TextField
        placeholder="New Email Address"
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlined />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder="Confirm New Email Address"
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlined />
            </InputAdornment>
          ),
        }}
      />
      <Button sx={{ bgcolor: '#5227CC', color: 'white', py: '.5rem', px: '1rem' }}>
        Confirm
      </Button>
    </Box>
  );
};

export default ChangeEmail;
