import { useMutation, UseMutationResult, UseMutationOptions } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import AuthContext from '../../context/AuthContext';

type GenericMutationParams<TData, TVariables> = UseMutationOptions<TData, Error, TVariables, unknown> & {
    mutationFn: (variables: TVariables) => Promise<TData>;
};

function useGenericMutation<TData, TVariables>(params: GenericMutationParams<TData, TVariables>): UseMutationResult<TData, Error, TVariables> {
    const { mutationFn, ...restOptions } = params;
    const { logout } = useContext(AuthContext); // Accessing logout from AuthContext

    const userIdentifier = Cookies.get("access_token");

    if (!userIdentifier) {
        toast.error("Your session has expired. Please login again!!!");
        logout();
    }

    return useMutation<TData, Error, TVariables>({
        mutationFn,  // Provide mutation function as part of options
        retry: (failureCount, error: any) => {
            //Dont retry incase the error code from the server is 401. Otherwise let it retry for 3 times(default retries)
            if (error.status === 401) {
                return false;
            }
            return failureCount < 2;
        },
        ...restOptions  // Spread any other options provided (like `onSettled`, `mutationKey`, etc.)
    });
}

export default useGenericMutation;
