import React from 'react';
import { Box, Typography } from "@mui/material";
import ChangePassword from './ChangePassword';
import Newsletter from './Newsletter';
import ChangeEmail from './ChangeEmail';

type Props = {};

const SettingsPage = (props: Props) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
      <Box>
        <Typography fontWeight={600} fontSize={'2rem'} lineHeight={'3rem'}>General Settings</Typography>
      </Box>
      <ChangeEmail />
      <ChangePassword />
      <Newsletter />
    </Box>
  );
};

export default SettingsPage;
