import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import AuthContext from '../../context/AuthContext';

type GenericQueryParams<TQueryFnData> = {
    queryKey: QueryKey;
    queryFn: () => Promise<TQueryFnData>;
    staleTime?: number | undefined;
    refetchOnWindowFocus?: boolean;
};

function useGenericQuery<TQueryFnData>(params: GenericQueryParams<TQueryFnData>): UseQueryResult<TQueryFnData, Error> {
    const { queryKey, queryFn, staleTime, refetchOnWindowFocus = true } = params;
    const { logout } = useContext(AuthContext); // Accessing logout from AuthContext

    const userIdentifier = Cookies.get("access_token")

    if (!userIdentifier) {

        toast.error("Your session has expired. Please login again!!!")
        logout();
    }




    // Return useQuery result with the proper types
    return useQuery<TQueryFnData, Error>({
        queryKey,
        queryFn,
        enabled: !!userIdentifier, // Automatically enable/disable based on userIdentifier
        staleTime,
        refetchOnWindowFocus,
        retry: (failureCount, error: any) => {
            //Dont retry incase the error code from the server is 401. Otherwise let it retry for 3 times(default retries)
            if (error.status === 401) {
                return false;
            }
            return failureCount < 2;
        }
    });
}

export default useGenericQuery;