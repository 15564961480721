
import { Box, CircularProgress } from '@mui/material';
import Feedback, { VideoDataFeedback } from '../../../interfaces/Feedback';
import { Question } from '../../../interfaces/Question';
import {
    CloseButton,
    FeedbackCard,
    FeedbackModalContainer,
    FeedbackModalHeader,
    FeedbackModalOverlay,
    FeedbackModalTopBar,
    FeedbackModalWrapper,
    ModalHeading,
    MoreDepthFeedback,
    QuestionDeckChatBot,
    QuestionDeckFavAndButton,
    VideoClipPlaceholder
} from '../QuestionCard.styles';

import ReviseComponent from './ReviseComponent';

interface FeedbackProps {
    feedback: Feedback,
    videoData: VideoDataFeedback,
    questionData: Question,
    handleCloseFeedbackModal: () => void,
    handleOpenChatBot: () => void
}

const FeedbackOverlay = ({ feedback, videoData, questionData, handleCloseFeedbackModal, handleOpenChatBot }: FeedbackProps) => {

    return (
        <FeedbackModalOverlay>

            <FeedbackModalWrapper>

                <FeedbackModalTopBar>
                    This is your feedback on the question ...
                    <CloseButton onClick={handleCloseFeedbackModal}>X</CloseButton>
                </FeedbackModalTopBar>
                <QuestionDeckFavAndButton style={{ justifyContent: 'flex-end' }}>
                    <QuestionDeckChatBot style={{ marginTop: '16px', marginRight: '24px' }} color="#FFF" TextColor="#006ecc" onClick={handleOpenChatBot}>ChatBot</QuestionDeckChatBot>
                </QuestionDeckFavAndButton>
                <FeedbackModalContainer>
                    <FeedbackModalHeader>{questionData.question}</FeedbackModalHeader>
                    {
                        feedback.data2?.generateVideo.valueOf() === true && !videoData?.data &&
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                            <CircularProgress />
                            <p>Loading video feedback</p>
                        </Box>
                    }
                    {videoData?.data && <VideoClipPlaceholder>
                        <video
                            controls
                            style={{ width: '100%', height: '100%', }}
                        >
                            <source src={videoData?.data} type="video/mp4" />
                        </video>
                    </VideoClipPlaceholder>}

                    <ModalHeading>Your Answer Was:</ModalHeading>
                    <MoreDepthFeedback>
                        <FeedbackCard color="#006ecc">{feedback?.data2?.feedback}</FeedbackCard>
                    </MoreDepthFeedback>
                    <ModalHeading>Feedback to answer:</ModalHeading>
                    <MoreDepthFeedback>
                        <FeedbackCard color="#e09f99">{feedback?.data2?.marksReasoning}</FeedbackCard>
                    </MoreDepthFeedback>
                    <ReviseComponent feedback={feedback!} question={questionData} />

                </FeedbackModalContainer>
            </FeedbackModalWrapper>
        </FeedbackModalOverlay>
    )
}

export default FeedbackOverlay
