
const StarSvg = () => {
    return (
        <svg><g xmlns="http://www.w3.org/2000/svg" id="Vector">
            <path d="M68.0005 76.0473L60.5852 80.1462L62.0014 71.4633L55.9999 65.3142L64.291 64.0473L68.0005 56.1462L71.7088 64.0473L79.9999 65.3142L73.9996 71.4633L75.4171 80.1462L68.0005 76.0473Z" fill="white" />
            <path d="M58.6112 79.8243L57.9482 83.889L61.5527 81.8966L68.0006 78.3325L74.4497 81.8967L78.0546 83.889L77.391 79.824L76.1362 72.1375L81.4314 66.711L84.1498 63.9252L80.302 63.3372L73.0659 62.2314L69.811 55.2965L68.0008 51.4396L66.1901 55.2963L62.9341 62.2314L55.6978 63.3372L51.8496 63.9252L54.5687 66.7112L59.865 72.1377L58.6112 79.8243Z" stroke="white" stroke-opacity="0.66" stroke-width="4" />
        </g></svg>
    )
}

export default StarSvg
