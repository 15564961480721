import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'katex/dist/katex.min.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import AppMain from './AppMain';
import { AuthProvider } from "./context/AuthContext";

function App() {


  return (
    <BrowserRouter>
      <ToastContainer />
      <AuthProvider>
        <AppMain />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
