const assets = {
  images: {
    logo: require("./images/LL-Logo.png"),
    readNotes: require("./images/read_notes.png")
  },
  gifs: {
    wavingBot: require("./images/bot-hello.gif"),
    botLoading: require("./images/bot-loading.gif")

  }
};
//"./images/bot-wave.gif"
//"./images/robot.gif"
export default assets;
