import Cookies from "js-cookie";
import useGenericMutation from "../components/shared/useGenericMutation";
import urlConfig from "../configs/urlConfig";
import { RevisionData, RevisionRequest } from "../interfaces/Revision";

export const useClassRevisionContent = () => {
    return useGenericMutation({
        mutationKey: ['revisionContent'],
        mutationFn: getClassRevisionContent,
        onSuccess: (data) => {
            return data;
        },
    });
}


export const useSendReviseLater = () => {
    return useGenericMutation({
        mutationKey: ['reviseLater'],
        mutationFn: sendReviseLater,
        onSuccess: (data: any) => {
            return data;
        },

        onError: (error) => {
            console.log('Error enrolling:', error);
        }
    });
}

const sendReviseLater = async (payload: any) => {

    const identifier = Cookies.get("access_token")
    payload = { ...payload, identifier: identifier }
    try {
        const response = await fetch(`${urlConfig.apiUrl}/reviseLater`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            mode: "cors",
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            // console.log(await response.json())
            return response.json()
        } else {
            console.log('Error:', response.status);
        }
    } catch (error) {
        console.log('Error:', error);
    }
};


const getClassRevisionContent = async (revisionData: RevisionData) => {

    const revisionRequest: RevisionRequest = {
        ContentName: revisionData.contentName,
        data: {
            originalClassId: revisionData.originalClassId,
            topics: [{
                topic: revisionData.topic
            }]
        },
        originalClassId: revisionData.originalClassId,
        subTopic: revisionData.subTopic,
        identifier: Cookies.get("access_token") || ''
    }
    const response = await fetch(`${urlConfig.apiUrl}/getRevisionContent`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(revisionRequest),
    });
    if (!response.ok) {
        throw response;
    }
    const data = await response.json()
    return data;
}