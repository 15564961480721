import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import AuthContext from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F8F9FA;
  min-height: 100vh;
  padding: 2rem;
`;

const AuthSection = styled.section`
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 500px;
`;

const AuthTitle = styled.h2`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-bottom: 0rem;
  gap: 2rem;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const Label = styled.label`
  position: absolute;
  left: 0px;
  top: -25px;
  font-size: 15px;
`;

const Input = styled.input`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  font-size: 13px;
`;


const ButtonSection = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
`;

const LoginButton = styled.button`
  background-color: #8A2BE2;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  width: 132px;
  height: 50px
`;

const OptionSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const LinkButton = styled.button`
  background: none;
  color: #8A2BE2;
  text-decoration: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

type Props = {};

const LogInPage = (props: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate()


  const handleLoginPress = () => {
    login(email, password)
  };

  const handleForgotPasswordPress = () => {
    console.log("Forgot Password pressed");
  };

  const handleSignUpPress = () => {
    navigate('/signup')
  };

  return (
    <LoginSection>
      <AuthSection>
        <AuthTitle>Student Login</AuthTitle>
        <Form>
          <InputGroup>
            <Input
              type="email"
              placeholder='mymail@gmail.com'
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              required
            />
            <Label>Email</Label>
          </InputGroup>
          <InputGroup>
            <Input
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              required
            />
            <Label>Password</Label>
          </InputGroup>
          </Form>

          <OptionSection>
            <CheckboxLabel>
              <Checkbox type="checkbox" />
              Remember me
            </CheckboxLabel>
            <LinkButton onClick={handleForgotPasswordPress}>Forgot password?</LinkButton>
          </OptionSection>


          <ButtonSection>
          <LoginButton onClick={handleLoginPress}  type="button">LOG IN</LoginButton>

          <p style={{textAlign: 'center'}}><i>Don't have an account?</i><br></br><LinkButton onClick={handleSignUpPress}><strong>SIGN UP</strong></LinkButton></p>
          </ButtonSection>
      </AuthSection>
    </LoginSection>
  );
};

export default LogInPage;
