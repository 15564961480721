import React from 'react';

type Props = {};

const ClassOne = (props: Props) => {
  return (
    <div>Computer Science</div>
  );
};

export default ClassOne;