import Cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import urlConfig from "../configs/urlConfig";

interface User {
    first_name: string;
    last_name: string;
    otp: number;
    phone_number: string;
    date_of_birth: string;
    email: string;
    uuid: string;
}

interface AuthContextType {
    signup: (userData: any) => Promise<void>;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
    authTokens: string | null;
    user: User | null;
    loading: boolean;
}

const defaultContextValue: AuthContextType = {
    signup: async () => { console.warn("signup function not implemented"); },
    login: async () => { console.warn("login function not implemented"); },
    logout: () => { console.warn("logout function not implemented"); },
    authTokens: null,
    user: null,
    loading: false,
};

const AuthContext = createContext<AuthContextType>(defaultContextValue);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [authTokens, setAuthTokens] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookie.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode<User>(token);
                setAuthTokens(token);
                setUser(decodedToken);
            } catch (error) {
                console.error("Token decoding error:", error);
                Cookie.remove("token");
            }
        }
        setLoading(false);
    }, []);

    const signup = async (userData: any) => {
        setLoading(true);
        try {
            const response = await fetch(`${urlConfig.apiUrl}/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();
            console.log(data);

            if (data.identifier) {
                toast.success("Signup successful! Welcome to LogicLand.");
                setAuthTokens(data.identifier);
                Cookie.set('access_token', data.identifier
                    , { expires: data.expiry }
                );
                navigate("/");
            } else {
                toast.error("Signup failed. Please try again.");
            }
        } catch (error) {
            console.error("Signup error:", error);
            toast.error("An error occurred during signup. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const login = async (email: string, password: string) => {
        setLoading(true);
        try {
            const response = await fetch(`${urlConfig.apiUrl}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });


            const data = await response.json()
            console.log(data)



            if (data.identifier) {
                setAuthTokens(data.identifier);
                Cookie.set('access_token', data.identifier, { expires: new Date(data.expiry) });
                // startSessionTimeout(data.expiry);
                navigate("/");
            } else {
                toast.error("Login failed. Please check your credentials and try again.");
            }
        } catch (error) {
            toast.error("Login failed. Please check your credentials and try again.");
            console.error("Login error:", error);
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        Cookie.remove('access_token');
        setUser(null);
        setAuthTokens(null);
        navigate("/login");
    };


    return (
        <AuthContext.Provider value={{ signup, login, logout, authTokens, user, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
