import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ChatBot from '../../components/common/Chatbot';
import { Module } from '../../interfaces/module';
import { useGetEnrollableModules } from '../../services/classes';

const Container = styled.div`
    background: #F8F9FA;
    min-height: 100vh;
    padding: 2rem;
`;

const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
`;

const MainTitle = styled.h2`
    margin: 0;
`;

const Mainheading = styled.p`
   margin: 0;
   font-size: 1rem;
`;

const SearchSection = styled.section`
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 2rem;
`;

const SearchBar = styled.input`
  flex-grow: 1;
  opacity: 80%;
  min-width: 8rem;
  max-width: 20rem;
  padding: 0.5rem;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  font-size: 16;
  &:focus {
    outline: none;
    border-color: #9b9ea1;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);
  }
`;

const Dropdown = styled.select`
  padding: 0.5rem;
  opacity: 60%;
  margin-right: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.8rem;
  background: white;
  &:focus {
    outline: none;v
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const CategoryDropdown = Dropdown;
const ExamBoardDropdown = Dropdown;

const SearchButton = styled.button`
  padding: 0.3rem 0.8rem;
  border: none;
  border-radius: 4px;
  background-color: #5227CC;
  color: white;
  font-size: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: #0069d9;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
`;

const ClassSection = styled.section`
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-radius: 10px;h
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background: #FFFF;
    overflow: hidden;
`;

const ClassColor = styled.div<{ color: string }>`
    width: 150px;
    background-color: ${props => props.color};
    
`;

const ClassDeck = styled.section`
    opacity: 90%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%
`;


const ClassDeckHeader = styled.h2`
    margin: 0;
    justify-content: space-between;
    display: flex;
`;


const ClassDescription = styled.p`
    margin: 0;
    font-size: 0.8rem;
`;


const ButtonSection = styled.section`
    display: flex;
    gap: 0.6rem;
`;

const Buttons = styled.button<{ color: string }>`
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    opacity: 100%;
    border: none;
    border-radius: 6px;
    background: ${props => props.color};
    color: #FFF;
    font-size: 16;
    cursor: pointer;
`;


const ChatBubbleButton = styled.button`
position: fixed;
bottom: 20px;
right: 20px;
background-color: #ffbf00;
border: none;
border-radius: 50%;
width: 60px;
height: 60px;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
z-index: 10;
`;

const EnrollPage: React.FC = () => {


    const [isChatBotOpen, setChatBotOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const { data: modules = [], isLoading } = useGetEnrollableModules();



    const handleOpenChatBot = () => {
        setChatBotOpen(true);
    };

    const handleCloseChatBot = () => {
        setChatBotOpen(false);
    };

    const handleNavigateWithModuleData = (module: Module): void => {
        navigate(`/enroll/subject-information/${encodeURIComponent(module._id)}`, { state: { module } });
    };

    const handleEnroll = (id: string): void => {
        const selectedModule = modules.find(module => module._id === id);
        if (selectedModule) {
            handleNavigateWithModuleData(selectedModule);
        }
    };

    if (isLoading) {
        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container>
            <TitleSection>
                <MainTitle>Enroll in a new class</MainTitle>
                <Mainheading>View all our available classes</Mainheading>
            </TitleSection>

            <SearchSection>
                <SearchBar placeholder="Search for a class" />
                <SearchButton>Search</SearchButton>
                <CategoryDropdown defaultValue="">
                    <option value="" disabled>Category</option>
                    <option value="All">All</option>
                    <option value="Humanities">Humanities</option>
                    <option value="STEM">STEM</option>
                </CategoryDropdown>

                <ExamBoardDropdown defaultValue="">
                    <option value="" disabled>Exam Board</option>
                    <option value="All">All</option>
                    <option value="AQA">AQA</option>
                </ExamBoardDropdown>

            </SearchSection>

            {modules.map(module => (
                <ClassSection key={module._id}>
                    <ClassColor color="#A000FE" />
                    <ClassDeck>
                        <ClassDeckHeader>
                            {module.Name}
                        </ClassDeckHeader>
                        <ClassDescription>{module.level} - {module.grade} - {module.board}</ClassDescription>
                        <ButtonSection>
                        <Buttons color="#7749F8" onClick={() => handleEnroll(module._id)}>Enroll in Class</Buttons>
                        </ButtonSection>
                    </ClassDeck>
                </ClassSection>
            ))}
            <ChatBot isOpen={isChatBotOpen} handleClose={handleCloseChatBot} />
            <ChatBubbleButton onClick={handleOpenChatBot}>
                <ChatBubbleIcon style={{ color: 'white', fontSize: '24px' }} />
            </ChatBubbleButton>
        </Container>
    );
};

export default EnrollPage;
