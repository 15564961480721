function hexToRgb(hex:string) {
    hex = hex.replace(/^#/, '');
    if (hex.length === 6) {
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return { r, g, b };
    }
    throw new Error('Invalid hex color');
}

export function generateColorMatrix(hexColor:string) {
    const { r, g, b } = hexToRgb(hexColor);
    const rNorm = r / 255;
    const gNorm = g / 255;
    const bNorm = b / 255;
    return `0 0 0 0 ${rNorm}
            0 0 0 0 ${gNorm}
            0 0 0 0 ${bNorm}
            0 0 0 0.25 0`;
}