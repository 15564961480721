import { Box, Typography, Container } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";

const Footer = () => {
  return (
    <Box
      sx={{
        py: 2,
        px: 3,
        backgroundColor: colorConfigs.footer.bg,
        color: colorConfigs.footer.color,
        borderTop: `1px solid ${colorConfigs.footer.borderColor}`,
        width: '100%',
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="body2" align="center" sx={{ fontWeight: 500 }}>
          © {new Date().getFullYear()} LogicLand, Inc. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
