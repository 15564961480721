
const CrownSvg = () => {
    return (
        <svg>
            <path xmlns="http://www.w3.org/2000/svg" d="M57.7361 59.2194L63.1265 63.9624L67.929 58.0493C68.0635 57.884 68.2335 57.7511 68.4265 57.6606C68.6194 57.5702 68.8303 57.5244 69.0433 57.5267C69.2564 57.529 69.4663 57.5794 69.6572 57.674C69.8481 57.7687 70.0152 57.9052 70.1461 58.0734L74.729 63.961L80.2937 59.15C80.5122 58.9615 80.7831 58.8442 81.0701 58.8138C81.3571 58.7835 81.6466 58.8415 81.8997 58.9802C82.1528 59.1188 82.3576 59.3315 82.4865 59.5897C82.6155 59.8479 82.6625 60.1394 82.6213 60.425L80.4963 75.0833H57.5562L55.3986 60.4916C55.3557 60.2044 55.4022 59.9109 55.5317 59.6509C55.6612 59.391 55.8674 59.1771 56.1225 59.0382C56.3776 58.8994 56.6692 58.8423 56.9578 58.8747C57.2465 58.9072 57.5181 59.0275 57.7361 59.2194ZM57.6667 76.4999H80.3333V77.9166C80.3333 78.2923 80.1841 78.6527 79.9184 78.9184C79.6527 79.184 79.2924 79.3333 78.9167 79.3333H59.0833C58.7076 79.3333 58.3473 79.184 58.0816 78.9184C57.8159 78.6527 57.6667 78.2923 57.6667 77.9166V76.4999Z" fill="white" />
            <g xmlns="http://www.w3.org/2000/svg" opacity="0.34">
                <path d="M53.2667 52.8457L61.0353 59.6812L67.9565 51.1593C68.1503 50.921 68.3954 50.7296 68.6734 50.5992C68.9515 50.4688 69.2554 50.4028 69.5625 50.4061C69.8696 50.4095 70.172 50.482 70.4471 50.6184C70.7223 50.7549 70.9631 50.9516 71.1517 51.194L77.7565 59.6791L85.7762 52.7456C86.0911 52.4739 86.4815 52.3049 86.8952 52.2611C87.3088 52.2174 87.726 52.301 88.0908 52.5008C88.4556 52.7007 88.7506 53.0072 88.9365 53.3793C89.1223 53.7514 89.1901 54.1715 89.1306 54.5831L86.0681 75.7083H53.0074L49.898 54.6791C49.8362 54.2651 49.9032 53.8422 50.0898 53.4675C50.2764 53.0929 50.5737 52.7846 50.9413 52.5845C51.3089 52.3844 51.7292 52.3022 52.1451 52.3489C52.5611 52.3956 52.9526 52.569 53.2667 52.8457ZM53.1667 77.7499H85.8333V79.7916C85.8333 80.3331 85.6182 80.8524 85.2354 81.2353C84.8525 81.6182 84.3332 81.8333 83.7917 81.8333H55.2083C54.6669 81.8333 54.1476 81.6182 53.7647 81.2353C53.3818 80.8524 53.1667 80.3331 53.1667 79.7916V77.7499Z" fill="white" />
            </g>
        </svg>

    )
}

export default CrownSvg
